import first from 'lodash/first';

export const colorTooltipsMixin = {
   methods: {
      addColorTooltips(tables, activities_colors, type = 'activity') {
         setTimeout(() => {
            tables.map(table => {
               const els = document.querySelectorAll(
                  table + ' .color-picker-container' + (type == 'pay' ? '-payment' : '') + ' .vc-compact-color-item'
               );
               for (let i = 0; i < els.length; i++) {
                  const ele = els[i];
                  const color = ele.getAttribute('aria-label').replace('color:#', '');
                  const title = this.statusName(activities_colors, color);
                  const icon = this.statusIcon(activities_colors, color);

                  if (typeof title != 'undefined') {
                     ele.setAttribute('title', title);
                  }

                  if (typeof icon != 'undefined') {
                     ele.setAttribute('data-content', icon);
                  }
               }
            });
         }, 50);
      },

      statusName(colors, status) {
         if (typeof status == 'string') {
            status = status.replace('#', '');
            status = status.toUpperCase();
         }
         return colors ? first(colors.filter(c => [c.d_color_code.replace('#', '').toUpperCase(), c.id].includes(status)))?.d_name.toUpperCase() : '';
      },

      statusIcon(colors, status) {
         if (typeof status == 'string') {
            status = status.replace('#', '');
            status = status.toUpperCase();
         }
         return colors ? first(colors.filter(c => [c.d_color_code.replace('#', '').toUpperCase(), c.id].includes(status)))?.d_icon.toUpperCase() : '';
      },
   },
};
